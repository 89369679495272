import React from "react";
import { compose } from "recompose";
import { Form, Col, Row, Button, Icon, Empty, notification } from "antd";
import { withApollo } from "react-apollo";
import { withGlobal } from "../../../component-global";
import { BodyContainer } from "./styles";
import CustomInput from "./CustomInput";
import CustomResource from "./CustomResource";
import moment from "moment";

// import * as route from "../../../routes/routes";

const coupImage =
  "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/nintendo%2FIconsGameboy%2Fcoup.png?alt=media&token=81e9bfa9-9c13-41d8-8649-56ddfd3a95b0";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVehicleMoto: false,
      initSetVehicleType: false,
    };
  }

  groupByToArray = (array, key) => {
    const groups = array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
    const tempKeys = {};
    const keys = array.reduce((acc, obj) => {
      if (tempKeys[obj[key]] !== 1) {
        acc.push(obj[key]);
        tempKeys[obj[key]] = 1;
      }
      return acc;
    }, []);

    const arrayOfGroup = keys.map(obj => {
      return {
        groupName: obj,
        groupDescription: groups[obj][0].groupDescription,
        fields: groups[obj],
      };
    });

    return arrayOfGroup;
  };

  groupCheck = (values, arrayFiltered) => {
    const totalQuestions = arrayFiltered.length;

    if (!totalQuestions) return;
    const correctsArray = arrayFiltered.filter(
      obj => values[obj.name] === obj.value
    );

    const wrongAnswers = totalQuestions - correctsArray.length;
    const title =
      wrongAnswers === 0
        ? "¡Felicitaciones! Has respondido con éxito todas las preguntas"
        : ` ¡Uy! Solo fallaste en ${wrongAnswers} de ${totalQuestions} respuestas del test`;

    this.props.setCustomModalMessage(
      title,
      `Estas listo para unirte a nuestra flota, miles de rutas y clientes te esperan.`,
      coupImage
    );
  };

  formatToSave = values => {
    let arrayFiltered = this.props.dataForm.fields.fields.filter(
      obj =>
        obj.type !== "video" &&
        obj.type !== "radio" &&
        obj.type !== "groupVideo"
    );

    if (this.state.isVehicleMoto) {
      arrayFiltered = arrayFiltered.filter(field => field.name === "categoryVehicleID");
    }

    const arrayGroupRadioFiltered = this.props.dataForm.fields.fields.filter(
      obj => obj.type === "radio"
    );

    this.groupCheck(values, arrayGroupRadioFiltered);
    const jsonArrayData = [];
    const arrayFormatted = arrayFiltered.map(obj => {
      let tempValue = values[obj.name];
      if (
        obj.type === "upload" &&
        Array.isArray(tempValue.fileList) &&
        tempValue.fileList.length
      ) {
        tempValue =
          tempValue.fileList[0] &&
          tempValue.fileList[0].response &&
          tempValue.fileList[0].response.url;
      }
      if(obj.jsonColum){
        jsonArrayData.push({
          field: obj.name,
          table: obj.table,
          idDB: obj.idDB,
          dataType: obj.dataType,
          value: `${tempValue}`,
        });
        return undefined;
      }
      return {
        field: obj.name,
        table: obj.table,
        idDB: obj.idDB,
        dataType: obj.dataType,
        value: `${tempValue}`,
      };
    }).filter(e => e);

    if( jsonArrayData.length > 0 ) {
      const jsonData = jsonArrayData.reduce((acc, obj) => {
        if (!acc[obj.table]) {
          acc[obj.table] = {};
        }
        if (obj.dataType === 'date') {
          acc[obj.table][obj.field] = /^\d+$/.test(obj.value)
          ? moment(new Date(Number(obj.value))).format("YYYY-MM-DD").toString()
          : moment(new Date(obj.value)).format("YYYY-MM-DD").toString();
        } else {
          acc[obj.table][obj.field] = obj.value;
        }
        // acc[obj.table][obj.field] = obj.dataType === 'date'
        //   ? moment(new Date(obj.value)).format("YYYY-MM-DD").toString()
        //   : obj.value;
        return acc;
      }, {});

      Object.entries(jsonData).forEach(([key, value]) => {
        arrayFormatted.push({
          field: 'extra',
          table: key,
          idDB: jsonArrayData.find(e => e.table === key).idDB,
          dataType: 'jsonb',
          value: JSON.stringify(value),
        });
      });
    }
    return arrayFormatted;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // Verifica la fecha de expiración de la licencia
        const dateToday = Date()
        if (values.driverLicenceExpirationDate &&
          moment(dateToday).format("YYYY-MM-DD") > moment(values.driverLicenceExpirationDate).format("YYYY-MM-DD")) {
          notification.error({
            message: 'No puede registrarse, la licencia está vencida'
          })
        } else {
          const result = this.formatToSave(values);
          this.props.onSaveChanges(result);
        }

      }
    });
  };

  onSelectChanse = e => {
    this.setState({
      isVehicleMoto: `${e}` === '234',
    })
  };

  verifyVehicleType = (step, isVehicleMoto) => {
    console.log("args: ", step, isVehicleMoto);

    if(!step.length) return;

    const filteredResult = step
      .flatMap(group => group.fields) // Extraemos todos los campos en un solo nivel.
      .filter(field => field.value !== null) // Filtramos los campos que tienen valores no nulos.
      .reduce((acc, field) => {
        acc[field.name] = field.value; // Construimos un objeto con name como clave y value como valor.
        return acc;
      }, {});
    this.setState({
      isVehicleMoto: `${filteredResult["categoryVehicleID"]}` === '234',
      initSetVehicleType: true,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isVehicleMoto, initSetVehicleType } = this.state;
    let data =
      this.props.dataForm && this.props.dataForm.fields
        ? this.groupByToArray(this.props.dataForm.fields.fields, "groupName")
        : [];
    if(!initSetVehicleType)
      this.verifyVehicleType(data, isVehicleMoto);

    if (isVehicleMoto) {
      data = data.map(group => {
        const filteredFields = group.fields.filter(field => field.name === "categoryVehicleID");
        if (filteredFields.length > 0) {
          return { ...group, fields: filteredFields };
        }
        return null;
      }).filter(group => group !== null);
    }

    const displayGroups = () =>
      data.map(obj => {
        return (
          <div key={obj.groupName}>
            <h3 className="groupName">{obj.groupName}</h3>
            {obj.groupDescription && (
              <div className="groupDescription">{obj.groupDescription}</div>
            )}
            <Row gutter={20}>
              {(obj || []).fields.map((item, idx) => {
                if (
                  item.type !== "video" &&
                  item.type !== "groupVideo" &&
                  item.type !== "radio"
                )
                  return (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                      key={item.order}
                    >
                      <CustomInput
                        placeholder={item.label}
                        type={item.type}
                        getFieldDecorator={getFieldDecorator}
                        decorator={item.name}
                        value={item.value}
                        options={item.inputSelectValues}
                        required={item.required}
                        disabled={this.props.disabledInputs}
                        onSelectChanse={this.onSelectChanse}
                        name={item.name}
                      />
                    </Col>
                  );
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    key={`res${idx + 1}`}
                  >
                    <CustomResource
                      placeholder={item.label}
                      type={item.type}
                      getFieldDecorator={getFieldDecorator}
                      decorator={item.name}
                      value={item.value}
                      options={item.inputSelectValues}
                      resources={item.resources}
                      name={item.name}
                      onSaveChanges={this.props.onSaveChanges}
                      loadingOnSave={this.props.loadingOnSave}
                      required
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      });

    return (
      <BodyContainer>
        <div className="WhiteContainer">
          {!this.props.currentStepID && !this.props.loadingRequest && (
            <Empty description="No se encontraron datos, probablemente haya un error con el usuario"></Empty>
          )}

          {this.props.completeTest ? (
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <h2>¡Felicitaciones {" "}{this.props.authUser.profile.given_name}!</h2>
                <p>
                  Te damos la bienvenida a Chazki, estamos deseosos de verte pronto
                  para poder entregarte tus implementos y presentarte al equipo acargo 
                  de brindarte el soporte que requieras durante la ruta.
                </p>
                <p>
                  Descarga la
                  <span style={{ color: "#307FE2" }}>
                    {" "}
                    App de Chazki Driver{" "}
                  </span>
                  y, si lo requieres, la
                  <span style={{ color: "#307FE2" }}>
                    {" "}
                    App de Dali.{" "}
                  </span>
                  ¡Inicia sesión y empieza a recorrer las calles!
                </p>
                <p>
                  Programa tu visita a las oficinas de{" "}
                  <span style={{ color: "#307FE2" }}>
                    {" "}
                    {this.props.authUser.profile.centralAddressCity}{" "}
                  </span>{" "}
                  para poder recibir los implementos y la asesoría de nuestro equipo.
                  Recuerda ir con la aplicación ya descargada (solo disponible para Android).
                  Escríbenos a soporte@chazki.com.
                </p>
                <p>¡Nos vemos pronto!</p>
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#307FE2",
                    color: "white",
                    borderColor: "#307FE2",
                    margin: 24,
                  }}
                  onClick={() => {
                    window.open(
                      // "https://play.google.com/store/apps/details?id=com.chazki.affiliatetonny"
                      "https://firebasestorage.googleapis.com/v0/b/chazki-link.appspot.com/o/apks%2Fprod%2Fapp-release.apk?alt=media&token=2c73c773-9534-482d-9887-4cfa6e42857e"
                    );
                  }}
                >
                  Descarga la App de Chazki <Icon type="download" />
                </Button>
                <Button
                  size="large"
                  style={{
                    backgroundColor: "#0CB8B1",
                    color: "white",
                    borderColor: "#0CB8B1",
                    margin: 24,
                  }}
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.paquery.droid.latam"
                    );
                  }}
                >
                  Descarga la App de Dali <Icon type="download" />
                </Button>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <Form>
                  <h2 className="titleStep">
                    {this.props.dataForm && this.props.dataForm.name}
                  </h2>
                  {this.props.dataForm &&
                    this.props.dataForm.description &&
                    this.props.dataForm.description
                      .replace(
                        /Nombre_usuario/gi,
                        this.props.userData
                          ? `${this.props.userData.names} ${this.props.userData.lastname}`
                          : null
                      )
                      .split("<br>")
                      .map((str, index) => <p key={index}>{str}</p>)}
                  {displayGroups()}
                  {this.props.dataForm &&
                    this.props.dataForm.proccessBD &&
                    this.props.dataForm.fields.fields[0].type !==
                      "groupVideo" && (
                      <div className="sendButton">
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#307FE2",
                            height: 48,
                          }}
                          type="submit"
                          onClick={this.handleSubmit}
                          size="large"
                          loading={this.props.loadingOnSave}
                          disabled={this.props.disabledInputs}
                        >
                          Siguiente
                          <Icon type="right" />
                        </Button>
                      </div>
                    )}
                </Form>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
            </Row>
          )}
        </div>
      </BodyContainer>
    );
  }
}

const WraperBody = withGlobal(Body);
const BodyForm = Form.create()(WraperBody);

export default compose(withApollo)(BodyForm);
